<template>
  <DaftarHadirRadirForm mode="Tambah" module="Daftar Hadir Radir"> </DaftarHadirRadirForm>
</template>

<script>
import DaftarHadirRadirForm from './form';

const DaftarHadirRadirAdd = {
  name: 'DaftarHadirRadirAdd',
  components: { DaftarHadirRadirForm },
};

export default DaftarHadirRadirAdd;
</script>
